
.before-footer{
  display:block;
  height: 50px;

}

.footer-logo{
  margin-bottom: 10px;
}

.scrolltotop{
  position:absolute;
  top:-30px;
  right:10px;
  display: block;
 // border: 1px solid #fff;
  padding: 5px 15px;
  background: @brand-color;

  &:hover{
    opacity: .8;
  }
  i{
    font-size: 24px;
  }
}
.footer {
  //background-image: url('../images/image-house.jpg');
  //background-repeat: no-repeat;
  //background-size: cover;
  background:#f5f5f5;
  //border-top:1px solid #5D6367;
  display: table;
  height: 1px;
  width: 100%;
  color: #000;

  //margin-top: 50px;
  position:relative;
  padding-top:100px;
  .container{
    position:relative;
  }



//  border-top: 20px solid @brand-color;

  h2,h3,h4{
    color: #000;
    font-size:24px;
    text-align:left;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1.28;
    padding-bottom: 5px;
    position:relative;
    margin: 0 0 10px;


  }

  a{
    color: #000;
  }
}

.footer-bottom {
//  color: #fff;
 // margin-top: 25px;
  padding:25px 0 15px 0;
//  background: @site-subfooter-bg;
  font-size: 13px;
  color: #000;
 // text-align: center;
  a{
    //color: @mycolor-red;
    color: #000;
    font-weight: bold;
  }

}
.footer-simple .footer-bottom {
   }
.footer-bottom a {
  //color: #fff;
}


.footer-bottom-left {
  float: left; }
@media (max-width: 767px) {
  .footer-bottom-left {
    float: none; } }

.footer-bottom-right {
  float: right; }
@media (max-width: 767px) {
  .footer-bottom-right {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    float: none;
    margin-top: 30px;
    padding-top: 30px; }
  .footer-simple .footer-bottom-right {
    border-top-color: #F5F5F5; } }

.footer-bar {
  background-color: #1976D2;
  color: #fff;
  padding-bottom: 7px;
  padding-top: 7px; }
.footer-bar .scroll-top {
  color: #fff;
  cursor: pointer;
  font-size: 40px; }

.footer input {
  background: none;
}

.footer .newsletter-module input[type=submit].btn-block{
  margin:0;
  background: @brand-color;
  border:none;
  &:hover{
    background: darken(@brand-color,15%);
  }
}